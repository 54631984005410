<template>
  <div id="footer" class="bg-stone-100 dark:bg-stone-950 lg:px-0 mt-24 pb-8 w-full sm:mt-32 lg:mt-40">
    <div class="container pt-8 px-4 md:px-8">
      <div id="footer-nav" class="flex flex-col md:flex-row flex-wrap md:justify-between md:items-center lowercase">
        <Navigation />
        <div class="flex mt-6 xl:mt-0 lg:justify-end w-full md:w-auto ">
          <NewsletterForm />
        </div>
      </div>
      <ul class="flex space-x-4 mt-12">
        <li v-for="item in socialMenu" :key="item.title" class="">
          <a :href="item.path" target="_blank" rel="noopener noreferrer" class="">
            <div v-html="item.icon" />
          </a>
        </li>
      </ul>
      <!-- Address -->
      <div class="flex flex-wrap justify-between">
        <address class="mt-4 text-base md:text-2xl  lowercase text-left not-italic">
          <p>292 Brunswick Ave</p>
          <p>Toronto, ON</p>
          <p>M5S 2M7</p>
          <p>416-923-8137</p>
        </address>

        <div class="flex flex-wrap flex-row items-end justify-end">
          <p class="text-sm text-center">
            <NuxtLink to="/" aria-label="Home">
              <Logo class="h-8 mb-4 mx-auto" fillOnHover />
            </NuxtLink>


            <NuxtLink to="/policies">policies</NuxtLink> • © 2024 TRANZAC
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const socialMenu = [
  {
    title: 'Facebook',
    path: 'https://www.facebook.com/tranzacTO',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12c0 4.84 3.44 8.87 8 9.8V15H8v-3h2V9.5C10 7.57 11.57 6 13.5 6H16v3h-2c-.55 0-1 .45-1 1v2h3v3h-3v6.95c5.05-.5 9-4.76 9-9.95"/></svg>',
  },

  {
    title: 'Twitter',
    path: 'https://twitter.com/tranzac',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M22.46 6c-.77.35-1.6.58-2.46.69c.88-.53 1.56-1.37 1.88-2.38c-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29c0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15c0 1.49.75 2.81 1.91 3.56c-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.2 4.2 0 0 1-1.93.07a4.28 4.28 0 0 0 4 2.98a8.52 8.52 0 0 1-5.33 1.84q-.51 0-1.02-.06C3.44 20.29 5.7 21 8.12 21C16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56c.84-.6 1.56-1.36 2.14-2.23"/></svg>',
  },
  {
    title: 'RSS',
    path: 'https://twitter.com/tranzac',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M5 21q-.825 0-1.412-.587T3 19t.588-1.412T5 17t1.413.588T7 19t-.587 1.413T5 21m12 0q0-2.925-1.1-5.462t-3-4.438t-4.437-3T3 7V4q3.55 0 6.625 1.325t5.4 3.65t3.65 5.4T20 21zm-6 0q0-1.675-.625-3.113T8.65 15.35t-2.537-1.725T3 13v-3q2.3 0 4.288.863t3.487 2.362t2.363 3.488T14 21z"/></svg>',
  },

]
</script>

<style>
#footer-nav a {
  @apply relative overflow-hidden text-2xl md:text-xl py-2 md:py-0 inline-block;

  &::after {
    content: '';
    @apply absolute bottom-0 left-0 h-0.5 bg-stone-950 dark:bg-cream transition-all duration-300 ease-in-out;
    width: 0;
  }

  &:hover {
    &::after {
      @apply w-full;
    }
  }
}
</style>