<template>
  <div>
    <form
      action="https://tranzac.us6.list-manage.com/subscribe/post?u=60d70505403758d09a5cd0a1e&amp;id=7f8b2295fc&amp;f_id=002754e5f0"
      method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank">
      <div
        class="md:flex items-center md:border-b-2 w-full md:dark:border-cream md:border-stone-900 justify-center mt-8 md:mt-4 lg:mt-0">
        <div class="flex items-center w-full max-w-md mr-4">
          <input type="email" name="EMAIL" id="mce-EMAIL" placeholder="name@sample.org" required
            class="min-w-64 w-full bg-transparent text-xl focus:outline-none focus:ring-0 border-b-2 dark:border-cream border-stone-900 md:border-0" />
        </div>
        <button type="submit" form="mc-embedded-subscribe-form"
          class="mt-4 md:mt-0 oval-button dark:-dark  md:inline-block px-4 py-2 my-2 text-sm">Subscribe</button>
      </div>
      <p class="mt-2 text-stone-400">Subscribe to our newsletter for weekly event updates, news, and more.</p>
      <div aria-hidden="true" style="position: absolute; left: -5000px;">
        <input type="text" name="b_60d70505403758d09a5cd0a1e_7f8b2295fc" tabindex="-1" value="">
      </div>
    </form>
  </div>
</template>