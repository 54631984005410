<script setup>
const props = defineProps({
  showDescription: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['linkClicked'])
const QUERY = `
  query {
    allMenuItems(orderBy: position_ASC) {
      title
      position
      path
      menuDescription
    }
  }
`;
const { data, error } = await useGraphqlQuery({ query: QUERY });

const linkClicked = () => {
  emits('link-clicked')
}
</script>
<template>
  <div>
    <ul class="nav">
      <li v-for="item in data.allMenuItems" :key="item.title" class="menu-item">
        <div class="description" v-if="showDescription">{{ item.menuDescription }}</div>
        <NuxtLink :to="item.path" @click="linkClicked">
          {{ item.title }}
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>



<style></style>