<template>
  <div class="fixed top-5 right-5 z-50 flex items-center justify-end">
    <div class="flex items-center space-x-4 backdrop-blur-sm rounded-md p-2">
      <template v-if="!userStore.userInfo?.email">
        <a @click.prevent="openLoginModal" href="#" class="text-cream text-sm hover:underline whitespace-nowrap">
          Login
        </a>
      </template>

      <template v-else>
        <div class="flex items-center space-x-4">
          <span v-if="userStore?.userInfo?.firstName" class="text-sm whitespace-nowrap">
            <NuxtLink to="/dashboard" class="underline">
              Hi, {{
                userStore?.userInfo?.firstName }}.
            </NuxtLink>
          </span>
          <NuxtLink v-if="hasPendingRequests" to="/dashboard/bookings" class="flex items-center space-x-2">
            <span class="underline text-sm cursor-pointer">View Booking Requests</span>
          </NuxtLink>
          <div @click="logout" class="underline text-sm cursor-pointer whitespace-nowrap">
            Logout
          </div>
        </div>
      </template>

      <button class="menu-toggle flex items-center cursor-pointer border-none ml-4" @click="toggleMenu"
        :aria-expanded="isMenuOpen" aria-controls="menu">
        <div class="menu-toggle_icon">
          <span :class="{ open: isMenuOpen }"></span>
        </div>
        <div class="menu-toggle_label text-sm whitespace-nowrap">{{ menuLabel }}</div>
      </button>
    </div>
  </div>

  <div id="menu" class="z-10 absolute top-0 flyover-menu h-full" :class="{ show: isMenuOpen }">
    <div class="flex flex-col h-full justify-start">
      <div id="logo" class="logo-alignment max-w-[1200px]">
        <NuxtLink to="/">
          <Logo />
        </NuxtLink>
      </div>
      <div class="z-20  h-full lg:mt-0 -mt-10 md:-mt-20">
        <Navigation :showDescription="true" @link-clicked="toggleMenu" />
      </div>
    </div>
  </div>
</template>


<script lang="ts" setup>
import { useRoute } from 'vue-router'
import { useUserStore } from "@/stores/userStore";
import { useAuth } from '~/composables/useAuth'

const bookingRequests = ref([]);
const { user, loggedIn, register, logout, sendLoginLink } = useAuth()
const isLoadingBookingRequests = ref(true);
const hasBookingRequests = computed(() => bookingRequests.value.length > 0);
const bookingRequestsCount = computed(() => bookingRequests.value.length);

const isMenuOpen = ref(false)
const route = useRoute()
console.log("bookingRequests", bookingRequests.value);
// New code to fetch booking requests

const BOOKING_REQUESTS_QUERY = `
  query BookingRequests($email: String!) {
  allRentals(filter: {primaryContactEmail: {eq: $email}}) {
    id
    inquiryStatus
    dates {
      slots {
        approvalStatus
      }
    }
  }
}
`;





onMounted(async () => {

  await userStore.fetchUserProfile();
  if (userStore.userInfo?.email) {
    await fetchBookingRequests();
  }
});

watch(route, () => {
  isMenuOpen.value = false
})

watch(isMenuOpen, (newValue) => {
  // document.body.classList.toggle('no-scroll', newValue)
})

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
}
const menuLabel = computed(() => (isMenuOpen.value ? 'Close' : 'Menu'));
const colorMode = useColorMode()


import { useLoginModalStore } from '@/stores/loginModalStore';

// Access the store
const loginModalStore = useLoginModalStore();

// Method to open the modal
function openLoginModal() {
  loginModalStore.open();
}


const userStore = useUserStore();
const fetchBookingRequests = async () => {
  isLoadingBookingRequests.value = true;
  try {
    const { data, error } = await useGraphqlQuery({
      query: BOOKING_REQUESTS_QUERY,
      variables: { email: userStore.userInfo.email },
      includeDrafts: true
    });

    if (data.value && data.value.allRentals) {
      bookingRequests.value = data.value.allRentals;
    } else {
      console.warn('No rental data found');
      bookingRequests.value = [];
    }

    if (error.value) {
      console.error('Error fetching booking requests:', error.value);
    }
  } catch (error) {
    console.error('Error in fetchBookingRequests:', error);
    bookingRequests.value = [];
  } finally {
    isLoadingBookingRequests.value = false;
  }
};
const hasPendingRequests = computed(() => {
  return bookingRequests.value.some(rental =>
    rental.inquiryStatus === 'pending' ||
    rental.dates.some(date =>
      date.slots.some(slot => slot.approvalStatus === 'pending')
    )
  );
});

</script>

<style>
.logo-blob {
  background-image:
    url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 1155" fill="rgba(247, 102, 102, 0.50)"><path d="M968.99 1033c-187.45.85-174.59 47.22-449 48-352 1-463-239-463-548 0-273.09 190-474 496-472 277.28 1.81 277.22 60.83 470 57 252-5 217.88-56.12 487-57 305-1 431 290 433 526s-117.08 502.4-432 507c-342 5-322-62-542-61Z"></path></svg>');
  background-position: center center;
  background-repeat: no-repeat;
}
</style>